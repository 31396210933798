import * as React from "react";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="September 2024" />
		<NewsHeader />
		<h2>September 2024</h2>

		<h3 className="blogdate">
			Wednesday, September 25, 2024
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">My first time singing in Portuguese</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Now, it's not my own song—it's a cover (my interpretation) of a beautiful song by Gilberto Gil, called <span className="italic">Aqui e Agora</span>.
			</p>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="https://www.youtube-nocookie.com/embed/385hxUuyDK4"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />
			<p>
				I'd love to know what you think of it.
				Also, I have a translation (English) ready and I'd like to record and release it as a single.
				I can't do that, though, without the involvement and blessing from Gilberto Gil or his team.
				Know anyone who knows someone and want to help me?
				Please get in <Link to="/contact/">contact</Link> 😎.
				(I know, I'm dreaming.)
			</p>
			<p>
				If you're in São Paulo this Friday, Sept 27th, I'll be playing at Cerne Cervejas at 19:00 sharp (come a bit earlier!).
				Want to join me? <Link to="/events/">Shows</Link>.
			</p>
			<p>
				Finally, I have a Patreon page and it's free to follow me there and see what I'm up to.
				I did this because Patreon is free of algorithmic tampering and, as such, one will actually see what I share once they follow me there.
				It's a very appropriate place (unlike the other obvious social media places) for sharing art and creating a community.
				I have zero expectations of anyone joining me there, but I feel resolved to spend more time there just the same.
				There's a link/icon near the top of this page should you wish to check it out.
			</p>

			<div className="main--centerwrapper p">
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
